@import "../../variables";
.button {
  height: 60px;
  width: 100%;
  padding: 0;

  color: $secondary-color;
  background: $primary-color none;
  font: inherit;
  font-size: 24px;

  border: none;
  border-radius: 5px;

  cursor: pointer;
}

.button[disabled=disabled], .button:disabled {
  background-color: #abaaa9;
  color: #eaeaea;
  cursor: not-allowed;
  &:hover {
    background-color: #abaaa9;
  }
}