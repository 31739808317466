@import '../../variables';
.header {
  background-color: $primary-color;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  width: 100%;
}

.imageContainer {
  flex: 1;
}
.imageContainer img {
  height: 30px;
}
