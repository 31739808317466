@import "../../variables";

.warning-alert {
  margin-top: 2rem;
  background-color: $orange100;
  border-left-width: 4px;
  border-color: $orange500;
  color: $orange700;
  padding: 1rem;
}

.success-alert {
  margin-top: 2rem;
  background-color: $green100;
  border-left-width: 4px;
  border-color: $green500;
  color: $green700;
  padding: 1rem;
}