.station-price-form {
  padding: 15px 25px 0 25px;
  @media screen and (min-width: 600px) {
    width: 600px;
  }
}

.station-price-form-container {
  @media screen and (min-width: 600px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.hidden-menu {
  opacity: 0;
  transition: opacity 0.25s ease;
}

.visible-menu {
  opacity: 1;
}